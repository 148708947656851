import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Sidebar from 'react-sidebar';

import SidebarContent from 'components/SidebarContent.js';

import Home from 'pages/Home.js'
import About from 'pages/About.js'
import Speaking from 'pages/Speaking.js'
import Workshops from 'pages/Workshops.js'
//import ModularTraining from 'pages/ModularTraining.js'
import Podcasts from 'pages/Podcasts.js'
import EventPlanners from 'pages/EventPlanners.js'
import ContactUs from 'pages/ContactUs.js'
//import BookEndorsements from 'pages/BookEndorsements.js'
import SpeakerEndorsements from 'pages/SpeakerEndorsements.js'
import VideoContent from 'pages/VideoContent.js'
// ADMIN
import AdminFormSubmissionDump from 'pages/AdminFormSubmissionDump.js'

const SIDEBAR_WIDTH = 200;
//const SIDEBAR_AUTO_DOCKED_MIN_WIDTH = 1200;

//const sidebarDockMql = window.matchMedia("(min-width: " + SIDEBAR_AUTO_DOCKED_MIN_WIDTH + "px)");

const sidebarStyles = {
  sidebar: {
    zIndex: 10,
    backgroundColor: '#00143D',
    maxWidth: SIDEBAR_WIDTH,
    width: '100%',
    overflowX: 'auto',
  }/*,
  content: {
    overflowY: 'hidden',
  }*/
};

// couldn't seem to get this to work as a method of App. but this is fine too
function PageRoute({path, parent, page: PageComponent}) {
  return (
    <Route path={path}>
      <PageComponent sidebarDocked={parent.state.sidebarDocked} sidebarButtonClicked={parent.sidebarButtonClicked}/>
    </Route>
  );
}


class App extends React.Component {
  state = {
    sidebarOpen: false,
    sidebarDocked: false,
  }

  onSetSidebarOpen = (open) => {
    this.setState({sidebarOpen: open});
  }

  sidebarButtonClicked = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      return {sidebarOpen: !prevState.open};
    });
  }

  dismissSidebarIfNotDocked = () => {
    if (!this.state.sidebarDocked) {
      this.setState({sidebarOpen: false});
    }
  }

  render() {
    return (
      <Router>
        <Sidebar sidebar={<SidebarContent dismissSidebarIfNotDocked={this.dismissSidebarIfNotDocked}/>}
                 styles={sidebarStyles}
                 transitions={this.state.sidebarDocked ? false : true}
                 open={this.state.sidebarOpen}
                 docked={this.state.sidebarDocked}
                 onSetOpen={this.onSetSidebarOpen}>
          {/* A <Switch> looks through its children <Route>s and renders the first one that matches the current URL. */}
          <Switch>
            <PageRoute parent={this} path="/about" page={About}/>
            <PageRoute parent={this} path="/speaking" page={Speaking}/>
            <PageRoute parent={this} path="/workshops" page={Workshops}/>
            <PageRoute parent={this} path="/endorsements" page={SpeakerEndorsements}/>
            {/* <PageRoute parent={this} path="/modular-training" page={ModularTraining}/> */}
            <PageRoute parent={this} path="/podcasts" page={Podcasts}/>
            <PageRoute parent={this} path="/event-planners" page={EventPlanners}/>
            <PageRoute parent={this} path="/contact-us" page={ContactUs}/>
            {/*  !! THIS IS BAD BECAUSE ITS DISCOVERABLE IN VIEW SOURCE !! */}
            <PageRoute parent={this} path="/admin-form-submission-dump" page={AdminFormSubmissionDump}/>
            {/* PageRoute parent={this} path="/vidcontent/:id" page={VideoContent}/> */}
            <Route path="/vidcontent/:id" render={(props) => <VideoContent id={props} sidebarDocked={this.state.sidebarDocked} sidebarButtonClicked={this.sidebarButtonClicked}/>} />
            <PageRoute parent={this} path="/" page={Home}/>
          </Switch>
        </Sidebar>
      </Router>
    );
  }
}

export default App;
