import { React, useState } from "react";
import styled from 'styled-components';

import Appbar from 'components/Appbar.js';

/* this works best for iPhone 6 or higher media query (375 x 667) */

const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-image: url(${process.env.PUBLIC_URL + "/images/speaking-bg.jpg"});
  background-position: center top;
  background-size: auto 100%;
  background-attachment: fixed;
`;

const FlexVertCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 56px);
`;

const TextContent = styled.div`
  // margin-top: calc(0.28 * (100vh - 32px));
  @media screen and (min-width: 1000px) {
    font-size: 20px;
    padding: 0 32px;
    max-height: calc(100vh - 88px);
  }
  @media screen and (max-width: 999px) {
    font-size: 15px;
    padding: 0 16px;
    max-height: calc(100vh - 56px);
  }
  background-color: #002060;
  max-width: 640px;

  overflow-y: auto;
`;

const Section = styled.div`
  margin: 16px 0;
`;

const TitleSpan = styled.span`
  font-size: 28px;
  font-weight: 800;
`;

const ClickTitle = styled.div`
  font-weight: 800;
  color: #3295ff;
  cursor: pointer;
  &:hover,
  &:active:hover {
    color: #7fbdff;
  }
  margin-bottom: 12px;
`;

const Para = styled.p`
  margin-bottom: 12px;
`;

const HardRule = styled.hr`
  border-top: 1px solid #0040A0;
  margin: 0 16px;
`;

const Checklist = styled.ul`
  li::marker {
    content: '✓';
  }
  li {
    padding-left: 8px;
  }
`;

const Speaking = ({sidebarDocked, sidebarButtonClicked}) => {
  const [selected, setSelected] = useState(0);
  return (
    <PageContainer>
      <Appbar title="Speaking" sidebarDocked={sidebarDocked} sidebarButtonClicked={sidebarButtonClicked}/>
      <FlexVertCenter>

        <TextContent>

          <Section><TitleSpan>KEYNOTES</TitleSpan> <i>(Tailored to your audience)</i><br/><br/></Section>


          <Section>

            <ClickTitle onClick={(e) => setSelected(1)}>
              Make Authentic Conference Connections: Utilize People Skills with Ease
            </ClickTitle>

            { selected === 1 &&
              <div>

              <Para>
                Highly interactive and inspirational! Perfect for conference openings and
                personal/professional development.
              </Para>

              <Para>
                Most attendees come to conferences to learn more about their industry and
                grow their network connections. Often there is time specifically designated
                on conference agendas to network. However, most people aren’t
                networking effectively.
              </Para>

              <Para>
                Kymberli is usually brought in at the beginning of a conference to inspire
                connection throughout the event. She frequently hears that she
                transformed someone’s understanding of what networking is and their new
                approach is still working years later.
              </Para>

              <Para><b>Key Takeaways:</b></Para>
              <Para>Attendees will learn</Para>

              <Checklist>
                <li>3 Foundational principles for authentic networking</li>
                <li>How to meet more people</li>
                <li>How to deepen relationships. (Not only will this be useful during the conference, but also in hybrid work environments.)</li>
              </Checklist>

              </div>
            }
          </Section>

          <HardRule/>


          <Section>

            <ClickTitle onClick={(e) => setSelected(2)}>
              Networking Redefined: Beyond Transactional to Transformational
            </ClickTitle>

            { selected === 2 &&
              <div>

              <Para>
                There is a version of networking that high-impact leaders use and
                understand that allows them to transcend beyond simply being an effective
                manager and leader to a transformational leader.
              </Para>

              <Para>
                Kymberli has worked with some of the best leaders in our U.S. military,
                teaching them how to build new solid, mutually beneficial relationships as
                they transition from decorated military service careers to executive c-suite
                roles in the civilian corporate setting for the past 9 years. She travels from
                coast to coast, sharing these nuggets with corporate audiences.
              </Para>

              <Para>
                Kymberli offers two keynotes on this topic (one for Leaders and one for
                Emerging Leaders).
              </Para>

              <Para><b>Key Takeaways for Leaders:</b></Para>

              <Checklist>
                <li>3 foundational principles for authentic networking</li>
                <li>How to develop trust, empower team members, and identify gaps</li>
                <li>How to expand their peer network to improve business decisions and inspire innovation that produces greater results</li>
              </Checklist>

              <Para><b>Key Takeaways for Emerging Leaders:</b></Para>

              <Checklist>
                <li>3 foundational principles for authentic networking</li>
                <li>How to position yourself to make transformational contributions</li>
                <li>How to gain visibility in your organization</li>
              </Checklist>

              </div>
            }
          </Section>

          <HardRule/>


          <Section>

            <ClickTitle onClick={(e) => setSelected(3)}>
              It’s an Inside Job: Networking for Internal Engagement
            </ClickTitle>

            { selected === 3 &&
              <div>

              <Para>
                Networking for Internal Engagement, Employee Retention, and Building Trust.
                Leaders and team members learn and experience high level teaming behavior through advanced
                networking concepts that focus on unique talent appreciation and collective trust.
              </Para>

              <Para><b>Key Takeaways:</b></Para>

              <Checklist>
                <li>How to build authentic relationships</li>
                <li>The 3 keys to building solid, mutually beneficial relationships</li>
                <li>Fundamentals of cohesive team-building that leads to higher-level performance and employee retention</li>
              </Checklist>

              </div>
            }
          </Section>

          <HardRule/>


          <Section>

            <ClickTitle onClick={(e) => setSelected(4)}>
              The Art of the Sale: Become More Known, Liked, and Trusted
            </ClickTitle>

            { selected === 4 &&
              <div>

              <Para>
                Great for conferences where sales or B2B growth is an important topic. If you
                are interested in long-term relationship sales, Kymberli’s approach, “build your
                network, the sale will come, is what you have been searching for!”
              </Para>

              <Para><b>Key Takeaways:</b></Para>

              <Checklist>
                <li>3 foundational principles for authentic networking</li>
                <li>How to create likeability and trust</li>
                <li>How to provide solution selling</li>
              </Checklist>

              </div>
            }
          </Section>

          <br/>

        </TextContent>

      </FlexVertCenter>
    </PageContainer>
  );
}

export default Speaking;
