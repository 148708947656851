import React from "react";
import styled from 'styled-components';

import Appbar from 'components/Appbar.js';
import Flex2ColWrap from 'components/Flex2ColWrap.js';

const TextContent = styled.div`
  flex: 1 1;
  min-width: 324px;
  max-width: 512px;
  margin-right: 16px;
  font-size: 17px;
`;

const Headshot = styled.img`
  width: 361px;
  height: 400px;
  /*margin-left: 32px;*/
`;

const Para = styled.p`
  margin-bottom: 24px;
`;

const PhotoCredit = styled.p`
  font-size: 12px;
  font-style: italic;
`;

export default function About(props) {
  return (
    <div>
      <Appbar title="About" sidebarDocked={props.sidebarDocked} sidebarButtonClicked={props.sidebarButtonClicked}/>
      <Flex2ColWrap>
        <TextContent>

          <Para>
            Kymberli Speight is THE CONFERENCE CONNECTOR — a recognized authority on
            networking. She is often brought in to kick off an event as she sets the tone for positive
            engagement, energetic interactions, and a memorable experience.
          </Para>
          <Para>
            She has served on several boards and was the 2021-22 Austin Chapter President for the
            National Speakers Association (NSA). Currently, Kymberli is a regional leader for NSA
            National.
          </Para>
          <Para>
            In corporate settings, Kymberli’s focus is on helping leaders and the individuals they lead
            rapidly expand their networks by sharing the vital and dynamic principles she learned
            from meeting 100 people in 100 days. She eradicates the barriers and challenges of
            successfully exchanging information with others.
          </Para>
          <Para>
            People are the Magic!
          </Para>
          <Para><i>
            “My father had a thriving real estate business built on referrals. I saw firsthand how his
            successful business model enriched his life and so many others who knew him. Now, I am
            excited to share how you can expand your network of opportunities and cherished
            friendships.”
          </i></Para>
          <Para>
            Kymberli Speight is a graduate of the United States Air Force Academy. She jumped out
            of planes, solo piloted a plane, and purchased 36 F-15E fighter jets (a $954 million buy) –
            all before turning 28 years old.
          </Para>

        </TextContent>
        <div>
          <Headshot src={process.env.PUBLIC_URL + "/images/kym-headshot-mewc-800.jpg"}/>
          <PhotoCredit>Photo by <a href="https://www.whitneyjfox.com/" target="_blank" rel="noreferrer">Whitney J. Fox Photography</a></PhotoCredit>
        </div>
      </Flex2ColWrap>
    </div>
  );
}
